import { createAsyncThunk } from "@reduxjs/toolkit";
import dealvpsService from "../../services/dealvpsService";

export const featchTransactionData = createAsyncThunk(
  "Transaction/fetchData",
  async (_, { rejectWithValue }) => {
    try {
      const userName = localStorage.getItem("userName");
      const res = await dealvpsService.get(
        // `/api/v1/general/get-all-table-data/Opportunity/${userName}`
        `/api/v1/general/get-all-table-data/Transaction`
      );
      
      return res.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
