import { createSlice } from "@reduxjs/toolkit";
import { featchGetById } from "../../api/ProjectService/featchGetById";




const initialState = {
  data: null,
  loading: false,
  error: null,
};

const ProjectGetByIdSlice = createSlice({
  name: "ProjectByIdDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(featchGetById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(featchGetById.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(featchGetById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default ProjectGetByIdSlice.reducer;
