import { createAsyncThunk } from "@reduxjs/toolkit";
import dealvpsService from "../../services/dealvpsService";

export const featchDealGetById = createAsyncThunk(
    "Dealbyid/fetchData",
    async (id, { rejectWithValue }) => {
      try {
        const userName = localStorage.getItem("userName");
        const res = await dealvpsService.get(
          `/api/v1/general/get-data-list-by-id/Deal/${id}`
        );
  
        return res.data;
      } catch (error) {
        return rejectWithValue(error?.response?.data);
      }
    }
  );
  