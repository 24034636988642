import { createAsyncThunk } from "@reduxjs/toolkit";
import dealvpsService from "../../services/dealvpsService";

export const fetchOpportunityById = createAsyncThunk(
    "opportunitybyid/fetchData",
    async (id, { rejectWithValue }) => {
      try {
        const userName = localStorage.getItem("userName");
        const res = await dealvpsService.get(
          `/api/v1/general/get-data-list-by-id/Opportunity/${id}`
        );
  
        return res.data;
      } catch (error) {
        return rejectWithValue(error?.response?.data);
      }
    }
  );
  