
import { createAsyncThunk } from "@reduxjs/toolkit";
import dealvpsService from "../../services/dealvpsService";

export const featchpotentialmaster = createAsyncThunk(
  "potential/fetchData",
  async () => {
    try {
      const userName = localStorage.getItem("userName");
      const res = await dealvpsService.post(
        // `/api/v1/general/query/GET_ALL_INDIVIDUAL_NAMES_FROM_INDIVIDUAL_MANAGEMENT/${userName}`
         `/api/v1/general/query/GET_VALUES_FROM_POTENTIAL_MASTER`,
         {}
      );
      
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);