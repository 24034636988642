import axios from "axios";
import { environment } from "../environment/environment";

const dealvpsService = axios.create({
    baseURL: environment.BASE_URL,
    headers: {
        "Content-Type": "application/json"
    }
});

dealvpsService.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        // const token ="f22d3b80784e2e6c524f966dd99a9a1e";
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export default dealvpsService;