
import { createAsyncThunk } from "@reduxjs/toolkit";
import dealvpsService from "../../services/dealvpsService";

export const featchStatus = createAsyncThunk(
  "DropStatus/fetchData",
  async () => {
    try {
      const userName = localStorage.getItem("userName");
      const res = await dealvpsService.post(
        // `/api/v1/general/query/GET_ALL_INDIVIDUAL_NAMES_FROM_INDIVIDUAL_MANAGEMENT/${userName}`
         `/api/v1/general/query/CORE_STATUS_MAPPING_VALUES`,
         {formName: "Opportunity"}
      );
      
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);