import { createSlice } from "@reduxjs/toolkit";
import { featchDealGetById } from "../../api/DealService/featchDealGetById";


const initialState = {
  data: null,
  loading: false,
  error: null,
};

const DealGetByIdSlice = createSlice({
  name: "DealByIdDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(featchDealGetById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(featchDealGetById.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(featchDealGetById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default DealGetByIdSlice.reducer;
